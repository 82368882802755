import React, { useState } from "react";

const Footer = ({logo, email}) => {
    return (
      <div className="bg-[#333436] flex py-[40px] px-[30px] md:px-[100px] justify-between items-center flex-wrap align-middle">
        <div className="w-[320px]">
          <img
            className="w-[320px]"
            src={logo}
            alt="Logo"
          />
          <div className="pt-[16px] text-[var(--Text-Tertiary,#999)] font-[Noto Sans] text-[12px] font-normal not-italic leading-normal lining-nums tabular-nums">
            © 2024 NeuinX All Rights Reserved.
          </div>
        </div>
        <div className="text-white text-[14px] flex gap-[40px] pt-[40px] sm:pt-0">
          <div>
            <div class="text-[var(--Text-Tertiary,#999)] font-['Noto_Sans_TC']  font-normal not-italic leading-normal pb-[16px]">
              Company
            </div>
            <div className={`pb-[8px] flex`}>NeuinX</div>
            <div>Contact for Business</div>
          </div>
          <div className="pt-[36px]">
            <div>{email}</div>
            <div className="flex pt-[8px] gap-[10px]">
              <img
                className="cursor-pointer w-[24px]"
                onClick={() => {
                  window.open(
                    "https://www.facebook.com/people/NeuinX/61551086316091/"
                  );
                }}
                src={`${process.env.PUBLIC_URL}/img/icon/facebook.svg`}
              />
              <img
                className="cursor-pointer w-[24px]"
                onClick={() => {
                  window.open("https://www.instagram.com/neuinx_tech/");
                }}
                src={`${process.env.PUBLIC_URL}/img/icon/instagram.svg`}
              />
              <img
                className="cursor-pointer w-[24px]"
                onClick={() => {
                  window.open("https://tw.linkedin.com/company/neuinx");
                }}
                src={`${process.env.PUBLIC_URL}/img/icon/linkedin.svg`}
              />
            </div>
          </div>
        </div>
      </div>
    );
}

export default Footer;