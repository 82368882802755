import React from "react";
import { connect } from "react-redux";
import Footer from "../../components/NeuinX/Footer/footer";
import Banner from "../../components/NeuinX/Banner/banner";
import Promotion from "../../components/NeuinX/Promotion/promotion";
import News from "../../components/NeuinX/News/news";
import { Translation } from "react-i18next";

import { ThemeProvider } from "@material-tailwind/react";

class Component extends React.Component {
  render() {
    return (
      <Translation>
        {(t, { i18n }) => (
          <React.Fragment>
            <Banner
              title={t("banner.title")}
              subtitle={t("banner.subtitle")}
              navbarItems={{
                news: t("navbar.news"),
                products: t("navbar.products"),
                contactUs: t("navbar.contactUs"),
              }}
            />
            <Promotion />

            <News />

            <Footer
              logo={`${process.env.PUBLIC_URL}/img/logo/neuinx_white.png`}
              email={`contact.us@neuinx.com`}
            />
          </React.Fragment>
        )}
      </Translation>
    );
  }
}

const Redux = connect((store) => ({}))(Component);

export const HomePage = Redux;
