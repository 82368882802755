import React from "react";

const Banner = () => {
    return (
      <React.Fragment>
        <div className="flex-grow px-0 ">
          <div
            className="bg-center bg-cover flex flex-col px-[28px] md:px-[152px] w-full min-h-screen"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/img/background/injoy-banner-background.svg)`,
            }}
          >
            {/* TODO: Add banner here */}
            <div
              className={`flex flex-wrap pt-[62px] pb-[60px] md:pb-0 md:pt-[90px] items-center gap-[300px]`}
            >
              <div className={`w-full md:w-[581px]`}>
                <div className={`text-[58px] font-black`}>
                  {`inJOY 提供球星般的專屬待遇  ，  `}
                  <span
                    className={`bg-gradient-to-r from-[#B170AD] to-[#FF6E72] bg-clip-text text-transparent`}
                  >
                    為你記錄
                  </span>
                  <span
                    className={`bg-gradient-to-r from-[#0060E5] via-[#1A65E8] to-[#FF6E72] bg-clip-text text-transparent`}
                  >
                    不容錯過的每一刻
                  </span>
                </div>
                <div
                  className={`text-gray-600 text-[18px] pt-[24px]`}
                >{`每場球賽，都由 inJOY 透過 AI 技術為你產出個人化的精華片段！`}</div>
              </div>
              <div
                className={
                  "relative flex-grow"
                }
              >
                <img
                  className={`w-[100%]`}
                  src={`${process.env.PUBLIC_URL}/img/background/banner_circle.svg`}
                  alt="Banner Background"
                />
                <img
                  className="w-[38%] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[2]"
                  src={`${process.env.PUBLIC_URL}/img/background/injoy_demo_1.png`}
                  alt="InJOY Demo"
                />

                {/* Adjusted Banner Ball */}
                <img
                  className="w-[10%] absolute top-[70%] left-1/2 transform -translate-x-[-150%] -translate-y-[230%]"
                  src={`${process.env.PUBLIC_URL}/img/background/banner_ball.svg`}
                  alt="Banner Ball"
                />

                {/* Banner Top Logo */}
                <img
                  className="w-[55%] absolute top-[40%] left-1/2 transform -translate-x-[10%] -translate-y-[144%] z-[3]"
                  src={`${process.env.PUBLIC_URL}/img/background/banner_top_logo.svg`}
                  alt="Banner Top Logo"
                />
                <img
                  className="w-[30%] absolute top-[45%] left-1/2 transform -translate-x-[-61%] -translate-y-[230%]"
                  src={`${process.env.PUBLIC_URL}/img/background/banner_white_line.svg`}
                />

                <img
                  className="w-[32%] absolute top-[45%] left-1/2 transform -translate-x-[150%] -translate-y-[-80%] z-[1]"
                  src={`${process.env.PUBLIC_URL}/img/background/banner_curly_gradient.svg`}
                />
                <img
                  className="w-[32%] absolute top-[45%] left-1/2 transform -translate-x-[145%] -translate-y-[-85%]"
                  src={`${process.env.PUBLIC_URL}/img/background/banner_curly_white.svg`}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
}

export default Banner;