import React from "react";
import { connect } from "react-redux";
import Footer from "../../components/NeuinX/Footer/footer";
import Banner from "../../components/InJoy/Banner/banner";
import Promotion from "../../components/InJoy/Promotion/promotion";
import { Translation } from "react-i18next";
import Navbar from "../../components/InJoy/Navbar/navbar";

class Component extends React.Component {
  render() {
    return (
      <Translation>
        {(t, { i18n }) => (
          <React.Fragment>
            {/* TODO: Navbar and Banner Section */}
            <div className={`bg-[#ECF2FB] w-full min-h-screen flex flex-col`}>
              <Navbar />
              <Banner />
            </div>
            {/* TODO: Video Section */}
            <Promotion />

            <Footer
              logo={`${process.env.PUBLIC_URL}/img/logo/injoy_logo_white.svg`}
            />
          </React.Fragment>
        )}
      </Translation>
    );
  }
}

const Redux = connect((store) => ({}))(Component);

export const InjoyPage = Redux;
