import React from "react";
import Navbar from "../Navbar/navbar";

const Banner = ({ title, subtitle, navbarItems }) => {
  return (
    <div
      className={`h-screen w-full bg-center bg-cover flex flex-col`}
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/img/background/Banner.png)`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
      }}
    >
      <Navbar navbarItems={navbarItems} />
      <div
        className={`px-[30px] md:px-[320px] flex-grow flex flex-col justify-center align-middle items-center pb-[100px]`}
      >
        <div
          style={{
            fontFamily: "Bebas Neue",
            letterSpacing: "0.05em",
            lineHeight: "1em",
            fontSize: "4.5em",
          }}
          className={`text-white font-bold text-lg p-3 text-center`}
        >
          {title}
        </div>
        <div className="text-white text-lg text-center">{subtitle}</div>
      </div>
    </div>
  );
};

export default Banner;
