import React from "react";
import Carousel from "react-bootstrap/Carousel";

import newsData from "./news-data.json";
import "bootstrap/dist/css/bootstrap.min.css";

const News = () => {
  return (
    <div
      className="flex items-center justify-center px-[30px] h-[400px] md:h-screen bg-[#000000]"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/img/background/Banner.png)`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
      }}
    >
      <div
        className="relative w-full md:max-w-[1000px] md:h-[500px] h-[300px] bg-gray-900 rounded-[24px] overflow-hidden"
        style={{
          aspectRatio: "16 / 9", // Maintains 16:9 aspect ratio
        }}
      >
        <Carousel>
          {newsData.map((item, index) => (
            <Carousel.Item key={index}>
              <div className="absolute top-0 w-full h-full bg-black opacity-50 rounded-[24px]"></div>
              <div className="w-full h-[300px] md:h-[500px]">
                <img
                  className="w-full h-full rounded-[24px] object-cover "
                  src={item.img}
                  alt={`Slide ${index + 1}`}
                />
              </div>

              <Carousel.Caption className="text-left mb-[40px]">
                {/* <div className="flex flex-col gap-[20px]"> */}
                <div className="flex justify-between">
                  <p className="text-gray-400 text-xs md:text-sm lg:text-base">
                    By {item.by}
                  </p>
                  <p className="text-gray-400 text-xs md:text-sm lg:text-base">
                    {item.date}
                  </p>
                </div>
                <h3 className="text-white text-lg md:text-xl lg:text-2xl">
                  {item.title}
                </h3>
                {/* </div> */}
                <p className="text-gray-300 text-sm md:text-base lg:text-lg overflow-hidden overflow-ellipsis whitespace-nowrap">
                  {item.content}
                </p>
                {/* <a
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-primary"
                >
                  Read More
                </a> */}
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default News;
