const Promotion = () => {
  return (
    <div className="flex items-center justify-center px-[30px] h-[400px] md:h-screen bg-[#ECF2FB]">
      <div
        className="relative w-[1000px] bg-gray-900 rounded-[24px]"
        style={{
          aspectRatio: "16 / 9", // Maintains 16:9 aspect ratio
        }}
      >
        <iframe
          className="absolute top-0 left-0 w-full h-full rounded-[24px]"
          src="https://www.youtube.com/embed/1GrM_Vx1h3Q?autoplay=1&loop=1&mute=1&playlist=1GrM_Vx1h3Q"
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
        />
      </div>
    </div>
  );
};

export default Promotion;
