import React, { useState } from "react";
const Navbar = () => {
    return (
      <React.Fragment>
        <div
          className={`px-[30px] md:px-[162px] flex items-center justify-between h-[80px]`}
        >
          {/* TODO: Add Navbar here */}
          <img
            className={`w-[204px]`}
            src={`${process.env.PUBLIC_URL}/img/logo/injoy_logo_black.svg`}
          />
        </div>
      </React.Fragment>
    );
}

export default Navbar;