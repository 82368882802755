import React from "react";
import i18next from "i18next";

const languageMap = {
  en: { label: "ENGLISH", dir: "ltr", active: true },
  zh: { label: "中文", dir: "ltr", active: false },
};

const LanguageSelectComponent = () => {
  const [selectedLanguage, setSelectedLanguage] = React.useState(
    localStorage.getItem("i18nextLng") || "en"
  );
  const [isDropdownVisible, setIsDropdownVisible] = React.useState(false);

  React.useEffect(() => {
    document.body.dir = languageMap[selectedLanguage].dir;
    localStorage.setItem("i18nextLng", selectedLanguage);
    i18next.changeLanguage(selectedLanguage); // Update i18next
  }, [selectedLanguage]);

  const handleLanguageChange = (key) => {
    setSelectedLanguage(key);
    setIsDropdownVisible(false); // Close dropdown after selection
  };

  return (
    <div
      style={{
        position: "relative",
        display: "inline-block",
        fontFamily: "Bebas Neue",
        letterSpacing: "0.2em",
        fontSize: "14px",
      }}
      onMouseEnter={() => setIsDropdownVisible(true)}
      onMouseLeave={() => setIsDropdownVisible(false)}
    >
      <div
        style={{
          cursor: "pointer",
          padding: "0px 10px",
        }}
      >
        {languageMap[selectedLanguage].label}
      </div>
      {isDropdownVisible && (
        <div
          style={{
            paddingTop: "10px",
            position: "absolute",
            top: "100%",
            left: "0",
            zIndex: 10,
          }}
        >
          {Object.keys(languageMap).map((key) => (
            <div
              key={key}
              style={{
                padding: "8px 10px",
                cursor: "pointer",
                background:
                  selectedLanguage === key ? "#e0e0e0" : "transparent",
              }}
              onClick={() => handleLanguageChange(key)}
            >
              {languageMap[key].label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSelectComponent;
