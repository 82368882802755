import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./assets/locals/en/translation.json";
import translationZH from "./assets/locals/zh/translation.json";

const fallbackLng = ["en"];
const availableLanguages = ["en", "zh"];

const resources = {
  en: {
    translation: translationEN,
  },
  zh: {
    translation: translationZH,
  },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng,
    lng: "en",
    detection: {
      checkWhitelist: true, // Update this to work with supportedLngs
    },
    debug: false,
    supportedLngs: availableLanguages, // Replace whitelist with this
    interpolation: {
      escapeValue: false,
    },
  });


export default i18n;
